<template>
  <div class="add-state-announcement add-template">
    <g-background>
      <page-header>
        <h1>{{ state_country_object.title }}</h1>
      </page-header>
      <page-body>
        <div class="page-navigation">
          <div class="nav-item nav-item-1" :class="{ active: page > 0 }">
            <div class="icon">
              <geo-dis />
            </div>
            <div class="text">{{ translations.tcGeographicDistribution }}</div>
          </div>
          <div class="sep" :class="{ active: page > 1 }"></div>
          <div class="nav-item nav-item-2" :class="{ active: page > 1 }">
            <div class="icon">
              <audience-icon />
            </div>
            <div class="text">{{ translations.tcAudience }}</div>
          </div>
          <div class="sep" :class="{ active: page > 2 }"></div>
          <div class="nav-item nav-item-3" :class="{ active: page > 2 }">
            <div class="icon">
              <message-icon />
            </div>
            <div class="text">{{ translations.tcEnterMessage }}</div>
          </div>
          <div class="sep" :class="{ active: page > 3 }"></div>
          <div class="nav-item nav-item-4" :class="{ active: page > 3 }">
            <div class="icon">
              <review-icon />
            </div>
            <div class="text">{{ translations.tcReviewAndSubmit }}</div>
          </div>
        </div>
      </page-body>
      <hr />
      <b-form @submit.prevent v-if="page === 1" class="view-1 g-mb-2">
        <h2 class="h-style-1 mb-3">
          {{ translations.tcDistributionLevel }}
          <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcDistributionLevel">
            <i-tooltip />
          </span>
        </h2>
        <div class="g-mb-1">
          <b-button-group>
            <b-button
              :variant="d_recipients === 'Full' ? 'primary' : 'outline'"
              @click="handleDistributionButtonClick('Full')"
              >{{ state_country_object.full }}</b-button
            >
            <b-button
              :variant="d_recipients === 'Selected' ? 'primary' : 'outline'"
              @click="handleDistributionButtonClick('Selected')"
              >{{ state_country_object.selected }}</b-button
            >
          </b-button-group>
        </div>
        <div v-if="d_recipients === 'Selected'">
          <h2 class="h-style-1 mb-3">{{ translations.tcAreas }}</h2>
          <div class="checkbox-row">
            <div v-for="(items, tx) in stateRegions" :key="tx">
              <GeographicAreas :geo_type="`region`" :area_prop="items"></GeographicAreas>
            </div>
          </div>
        </div>
      </b-form>
      <b-form @submit.prevent v-if="page === 2" class="view-2 g-mb-2">
        <h2 class="h-style-1 mb-3">
          {{ translations.tcAudience }}
          <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcAudience">
            <i-tooltip />
          </span>
        </h2>
        <div class="g-mb-1">
          <b-button-group>
            <b-button
              v-if="d_recipients === 'Full'"
              @click="filterItems('State', null)"
              :variant="l_recipients === 'State' ? 'primary' : 'outline-primary'"
              >{{ state_country_object.leadership }}</b-button
            >
            <b-button
              @click="filterItems('Camp', null)"
              :variant="l_recipients === 'Camp' ? 'primary' : 'outline-primary'"
              >{{ translations.tcCampOfficerGroups }}</b-button
            >
            <b-button
              @click="filterItems('Members', null)"
              :variant="l_recipients === 'Members' ? 'primary' : 'outline-primary'"
              >{{ translations.tcMembers }}</b-button
            >
          </b-button-group>
        </div>
        <h2 class="h-style-1 mb-3">{{ translations.tcMemberType }}</h2>
        <div class="d-flex g-mb-1 member-type">
          <b-button-group>
            <b-button
              @click="filterItems(null, 'Both')"
              :variant="g_recipients === 'Both' ? 'primary' : 'outline-primary'"
              >{{ translations.tcBoth }}</b-button
            >
            <b-button
              @click="filterItems(null, 'Gideon')"
              :variant="g_recipients === 'Gideon' ? 'primary' : 'outline-primary'"
              >{{ translations.tcGideon }}</b-button
            >
            <b-button
              @click="filterItems(null, 'Auxiliary')"
              :variant="g_recipients === 'Auxiliary' ? 'primary' : 'outline-primary'"
              >{{ translations.tcAuxiliary }}</b-button
            >
          </b-button-group>
          <div v-if="showElectedCheckbox">
            <b-form-checkbox
              @change="handleIncludeCheckboxChange($event)"
              v-model="include_state_elected_officers"
              name="include-elected"
              inline
              >{{ state_country_object.include }}</b-form-checkbox
            >
          </div>
        </div>
        <span v-if="l_recipients === 'Camp'">
          <h2 class="h-style-1 mb-3">{{ leadership_title }}</h2>
          <div class="checkbox-row">
            <div v-for="(items, lx) in filteredItems.items" :key="lx">
              <GeographicAreas :geo_type="filteredItems.type" :area_prop="items"></GeographicAreas>
            </div>
          </div>
        </span>
      </b-form>
      <b-form @submit.prevent v-if="page === 3" class="view-3 g-mb-2">
        <AnnouncementTemplate
          :form="form"
          :nextButtonText="translations.tcNext"
          @announcement_validated="handleNextClick"
          @announcement_cancel="handleCancelClick"
          @backClick="handleBackClick"
        />
      </b-form>
      <div v-if="page === 4">
        <AnnouncementReview
          :includeOfficers="include_state_elected_officers"
          :audienceDistribution="audienceDistribution"
          :state_country_object="state_country_object"
          :selectedStateRegions="selectedStateRegions"
          :d_recipients="d_recipients"
          :l_recipients="l_recipients"
        ></AnnouncementReview>
      </div>
      <section v-if="page !== 3" class="form-buttons">
        <b-button variant="primary" class="mr-3" v-if="page > 1 && page < 4" @click="page--">
          {{ translations.tcBack }}
        </b-button>
        <b-button variant="primary" class="mr-3" v-if="page < 4" @click="handleNextClick">
          {{ translations.tcNext }}
        </b-button>
        <b-button variant="tertiary" v-if="page < 4" @click="handleCancelClick">
          {{ translations.tcCancel }}
        </b-button>
        <b-button variant="primary" class="mr-3" v-if="page === 4" @click="handleSubmit">
          {{ translations.tcSubmit }}
        </b-button>
        <b-button variant="tertiary" v-if="page === 4" @click="page = 1">
          {{ translations.tcEdit }}
        </b-button>
      </section>
    </g-background>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AnnouncementTemplate from '@/components/AnnouncementTemplate.vue'
import AnnouncementReview from '@/components/AnnouncementReview.vue'
import audienceIcon from '@/assets/svgs/audience-icon.vue'
import gBackground from '@/components/gBackground.vue'
import GeoDis from '@/assets/svgs/geographic-distribution-icon.vue'
import GeographicAreas from '@/components/GeographicAreas.vue'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iPaperclip from '@/assets/svgs/iPaperclip.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import messageIcon from '@/assets/svgs/message-icon.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import reviewIcon from '@/assets/svgs/review-icon.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-state-announcement',
  mixins: [translationMixin],
  data() {
    return {
      cabinetTypes: {
        'Auxiliary Areas': 'tcAuxiliaryAreas',
        'Auxiliary Camp Cabinet': 'tcAuxiliaryCampCabinet',
        'Auxiliary Members': 'tcAuxiliaryMembers',
        'Auxiliary State Leadership': 'tcAuxiliaryStateLeadership',
        'Gideon Areas': 'tcGideonAreas',
        'Gideon Camp Cabinet': 'tcGideonCampCabinet',
        'Gideon Members': 'tcGideonMembers',
        'Gideon State Leadership': 'tcGideonStateLeadership',
      },
      d_recipients: 'Full',
      dateOptions: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }, // `Thursday, August 26, 2021`
      filteredItems: {
        type: '',
        items: [],
      },
      form: {},
      g_recipients: 'Both',
      include_state_elected_officers: false,
      l_recipients: 'State',
      leadership_title: '',
      localeAndMemberTypes: [
        'campAllLeaders',
        'campAuxiliaryLeaders',
        'campGideonLeaders',
        'stateAllLeaders',
        'stateAuxiliaryLeaders',
        'stateAuxiliaryManagement',
        'stateAuxiliaryMembers',
        'stateBothManagement',
        'stateBothMembers',
        'stateGideonLeaders',
        'stateGideonManagement',
        'stateGideonMembers',
      ],
      page: 1,
      state_country_object: {
        title: '',
        include: '',
        full: '',
        selected: '',
        leadership: '',
      },
      translations: {},
    }
  },
  watch: {},
  async created() {
    try {
      await Promise.all([
        await this.setLoadingStatus(true),
        await this.getViewTranslations('geographic-areas'),
        await this.getComponentTranslations('common.officer-titles'),
        await this.loadForEdit(),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)

        this.setStateCountryObject()
        this.translateOfficerText()
        this.translateStateRegionText()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      announcementForm: 'announcements/announcementForm',
      campAllLeaders: 'announcements/campAllLeaders',
      campAuxiliaryLeaders: 'announcements/campAuxiliaryLeaders',
      campGideonLeaders: 'announcements/campGideonLeaders',
      demograph: 'user/demograph',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      selectedStateRegions: 'announcements/selectedStateRegions',
      stateAllLeaders: 'announcements/stateAllLeaders',
      stateAuxiliaryLeaders: 'announcements/stateAuxiliaryLeaders',
      stateAuxiliaryManagement: 'announcements/stateAuxiliaryManagement',
      stateAuxiliaryMembers: 'announcements/stateAuxiliaryMembers',
      stateBothManagement: 'announcements/stateBothManagement',
      stateBothMembers: 'announcements/stateBothMembers',
      stateGideonLeaders: 'announcements/stateGideonLeaders',
      stateGideonManagement: 'announcements/stateGideonManagement',
      stateGideonMembers: 'announcements/stateGideonMembers',
      stateRegions: 'announcements/stateRegions',
      userCamp: 'user/userCamp',
      userCountryObject: 'user/userCountryObject',
      userId: 'user/userId',
      userStateKey: 'user/userStateKey',
      userStateName: 'user/userStateName',
    }),
    bothGideonsAuxiliary() {
      return { b: 'Both', a: 'Auxiliary', g: 'Gideon' }[this.announcementForm.msg_member_type || 'b']
    },
    selectedFull() {
      return !this.announcementForm.type
        ? 'Full'
        : this.announcementForm.type.filter((sf) => {
            return sf.includes('Areas') || sf.includes('Zones')
          }).length > 0
        ? 'Selected'
        : 'Full'
    },
    managementMembersCampState() {
      if (!this.announcementForm.type || this.announcementForm.type.length === 0) {
        return 'State'
      } else {
        return this.announcementForm.type[0].replace('Areas', '').replace('Zones', '').replace('Full', '')
      }
    },
    hasWholeCabinet() {
      return this.l_recipients === 'Camp'
    },
    audienceDistribution() {
      return this.filteredItems.items.map((im) => {
        return {
          title:
            this.d_recipients === 'Full' && this.userCountryObject.type !== 'domestic'
              ? im.title.replace('State', 'Country')
              : im.title,
          key: im.key,
          areas: im.areas.filter((af) => af.selected === true || af.selected === 'true'),
        }
      })
    },
    showElectedCheckbox() {
      return this.l_recipients === 'Camp' || (this.d_recipients === 'Selected' && this.l_recipients === 'Members')
    },
  },
  methods: {
    ...mapActions({
      addUpdateAnnouncementMessage: 'announcements/addUpdateAnnouncementMessage',
      setIncludeSwitch: 'announcements/setIncludeSwitch',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMembers: 'announcements/setMembers',
      setMessagePackageAudience: 'announcements/setMessagePackageAudience',
      setMessagePackageGeography: 'announcements/setMessagePackageGeography',
      setMessagePackageMessage: 'announcements/setMessagePackageMessage',
      setMessagePackageMessageInclude: 'announcements/setMessagePackageMessageInclude',
      setStateRegion: 'announcements/setStateRegion',
    }),
    handleDistributionButtonClick(dist) {
      this.d_recipients = dist
      this.l_recipients = dist === 'Full' ? 'State' : 'Management'
    },
    async handleBackClick() {
      this.page--
      if (this.page === 2) {
        await this.filterItems(this.l_recipients, this.g_recipients)
      }
      if (this.page === 3) {
        document.documentElement.scrollTop = 0
      }
    },
    handleIncludeCheckboxChange(evt) {
      this.setIncludeSwitch(evt)
    },
    async loadForEdit() {
      this.include_state_elected_officers = this.announcementForm.includeElected || false
      this.d_recipients = this.selectedFull
      this.g_recipients = this.bothGideonsAuxiliary
      this.l_recipients = this.managementMembersCampState
      await (async () => {
        this.form.msg_message_id = this.announcementForm.msg_message_id
        this.form.msg_member_type = this.announcementForm.msg_member_type
        this.form.notification = this.announcementForm.notification
        this.form.title = this.announcementForm.title
        this.form.expires = this.announcementForm.expires
        this.form.message = this.announcementForm.message
        this.form.fileArray = this.announcementForm.fileArray
        this.form.tempFileArray = this.announcementForm.tempFileArray
        this.presetDate =
          this.announcementForm.expires === null || this.announcementForm.expires.toString().includes('0001')
            ? null
            : this.announcementForm.expires
      })()
    },
    async preFilter(l, g) {
      this.l_recipients = l || this.l_recipients
      this.g_recipients = g || this.g_recipients
      await this.setMembers(this.g_recipients)
      this.filteredItems.items = []
      this.filteredItems.type = `${g || this.g_recipients}${l || this.l_recipients}`
      this.leadership_title = (l || this.l_recipients) === 'Camp' ? this.translations.tcOfficerGroups : ''
    },
    async filterItems(l, g) {
      await this.preFilter(l, g)
      this.filteredItems.items = await (async () => {
        switch (this.filteredItems.type) {
          case 'AuxiliaryCamp':
            return this.campAuxiliaryLeaders
          case 'AuxiliaryManagement':
            return this.stateAuxiliaryManagement
          case 'AuxiliaryMembers':
            return this.stateAuxiliaryMembers
          case 'AuxiliaryState':
            return this.stateAuxiliaryLeaders
          case 'BothCamp':
            return this.campAllLeaders
          case 'BothManagement':
            return this.stateBothManagement
          case 'BothMembers':
            return this.stateBothMembers
          case 'BothState':
            return this.stateAllLeaders
          case 'GideonCamp':
            return this.campGideonLeaders
          case 'GideonManagement':
            return this.stateGideonManagement
          case 'GideonMembers':
            return this.stateGideonMembers
          case 'GideonState':
            return this.stateGideonLeaders
          default:
            return this.l === 'Camp' ? this.campAllLeaders : this.l === 'State' ? this.stateAllLeaders : []
        }
      })()
    },
    async handleCancelClick() {
      this.$router.push('/get-connected/stay-informed/announcements')
    },
    async handleSubmit() {
      try {
        this.setLoadingStatus(true)
        await this.setMessagePackageMessage({
          user: this.userId,
          sendername: this.demograph.informal_name + ' ' + this.demograph.last_name,
          senderemail: this.demograph.email,
        })
        if (this.d_recipients == 'Full') {
          await this.setMessagePackageGeography({
            statekey: this.userStateKey,
            location: this.userStateKey,
            type: this.d_recipients,
            user: this.userId,
            ordinal: 0,
          })
        } else {
          await (async () => {
            this.selectedStateRegions.map((ssr) => {
              ssr.areas.map((ssra, ix) => {
                this.setMessagePackageGeography({
                  statekey: this.userStateKey,
                  location: ssra.value,
                  location_name: ssra.area,
                  location_all: ssra.area === ssr.region,
                  type: 'Areas',
                  user: this.userId,
                  ordinal: ix,
                })
              })
            })
          })()
        }
        await (async () => {
          this.filteredItems.items.map((fi) => {
            // if the first area is selected, it's the 'Whole Cabinet' option
            if (this.hasWholeCabinet && fi.areas[0].selected) {
              fi.areas.map((fia) => {
                fia.selected = !fia.selected
              })
            }
            if (this.announcementForm.msg_message_id === null) {
              if (this.filteredItems.type.includes('State')) {
                fi.areas.map((fia) => {
                  fia.selected = !fia.selected
                })
              }
            }
            fi.areas.map((fm, fmx) => {
              if (fm.selected) {
                this.setMessagePackageAudience({
                  audience: fm.value,
                  type: this.l_recipients,
                  user: this.userId,
                  ordinal: fmx,
                })
              }
            })
          })
        })()
        let add_update_result = await this.addUpdateAnnouncementMessage({
          // Check with Josh to make sure flag is working as intended
          include: this.include_state_elected_officers,
          membertype: this.g_recipients.substr(0, 1).toLowerCase(),
          messageid: this.form.msg_message_id,
        })
        this.setLoadingStatus(false)
        this.$swal({
          icon: add_update_result ? 'success' : 'error',
          text: add_update_result
            ? this.translations.tcAnnouncementSaved
            : this.translations.tcErrorAnnouncementNotSaved,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then(() => {
          if (add_update_result) {
            this.$router.push('/get-connected/stay-informed/announcements')
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleNextClick() {
      if (this.page === 1 && this.d_recipients !== 'Full' && this.selectedStateRegions.length === 0) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcOneOrMoreAreasRegionsMustBeSelected,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      } else {
        let adCount = 0
        this.audienceDistribution.forEach((fe) => (adCount += fe.areas.length))
        if (this.page === 2 && adCount === 0 && this.l_recipients === 'Camp') {
          this.$swal({
            icon: 'error',
            text: this.translations.tcOneOrMorePositionsMustBeSelected,
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
          return
        }
        this.page++
        if (this.page === 3) {
          document.documentElement.scrollTop = 0
        }
        if (this.page === 2) {
          if (this.d_recipients === 'Selected' && this.l_recipients === 'Management') {
            this.l_recipients = 'Camp'
          }
          await this.filterItems(this.l_recipients, this.g_recipients)
        }
      }
    },
    async setStateCountryObject() {
      this.state_country_object =
        this.userCountryObject.type === 'domestic'
          ? {
              title: `${this.translations.tcAddStateAnn} - ${
                !!this.officerToolbarSelected.country_state_name
                  ? this.officerToolbarSelected.country_state_name
                  : this.userStateName
              }`,
              include: this.translations.tcIncludeStateOfficers,
              full: this.translations.tcFullState,
              selected: this.translations.tcSelectedAreas,
              leadership: this.translations.tcStateLeadershipOnly,
            }
          : {
              title: `${this.translations.tcAddCountryAnn} - ${
                !!this.officerToolbarSelected.country_state_name
                  ? this.officerToolbarSelected.country_state_name
                  : this.userCountryObject.name
              }`,
              include: this.translations.tcIncludeNationalLeadership,
              full: this.translations.tcFullCountry,
              selected: this.translations.tcSelectedZones,
              leadership: this.translations.tcCountryLeadershipOnly,
            }
    },
    translateStateRegionText() {
      if (!this.translations || !this.stateRegions) return false

      let allStateRegions = this.stateRegions.map((region) => {
        const updatedRegion = { ...region }
        // Translate `Region`/`Area` title (IE, 'State Region East')
        let regionVal = region.title ? region.title : ''
        regionVal = regionVal ? `tc${this.removeSpacesAndSpecialChars(regionVal)}` : ''
        updatedRegion.title = this.translations.hasOwnProperty(regionVal) ? this.translations[regionVal] : region.title

        // Translate specific Region/Area (IE, 'State Area 010')
        updatedRegion.areas = updatedRegion.areas.map((area) => {
          if (area.text && area.text.includes('State Area')) {
            const splitTitle = area.text.split('State Area')
            const stateAreaNum = splitTitle[1] // `001` or `003`
            const stateAreaText = `${this.translations.tcStateArea}${stateAreaNum}`
            area.text = stateAreaText ? stateAreaText : area.text
          } else {
            const lookupName = area.text ? `tc${this.removeSpacesAndSpecialChars(area.text)}` : ''
            area.text = this.translations.hasOwnProperty(lookupName) ? this.translations[lookupName] : area.text
          }
          return area
        })
        return updatedRegion
      })
      return this.setStateRegion(allStateRegions)
    },
  },
  components: {
    AnnouncementTemplate: AnnouncementTemplate,
    AnnouncementReview: AnnouncementReview,
    audienceIcon: audienceIcon,
    gBackground: gBackground,
    GeoDis: GeoDis,
    GeographicAreas: GeographicAreas,
    iCalendar: iCalendar,
    iPaperclip: iPaperclip,
    iTooltip: iTooltip,
    messageIcon: messageIcon,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    reviewIcon: reviewIcon,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';
// form template for add-template on AddCampAnnouncement.vue USE AddCampAnnouncement as include

.add-template {
  @include breakpoint(sm) {
    .page-header {
      text-align: center;
      h1 {
        font-size: 42px;
      }
    }
  }
  .button-group {
    .btn-primary {
      border: 1px solid #003946;
      text-transform: uppercase !important;
    }
  }
  .g-background {
    margin: 90px auto 104px;
  }
  .form-section {
    font-size: 22px;
    font-weight: bold;
    display: block;
    line-height: 44px;
    letter-spacing: 0.73px;
    text-transform: uppercase;
    color: #636363;
    margin-bottom: 10px;
  }
  .form-block {
    .form-element {
      margin-bottom: 10px;

      &.form-attachment {
        margin-bottom: 60px;
        margin-top: 45px;
        @include breakpoint(sm) {
          margin-top: 25px;
        }
      }
    }
  }
  .form-control {
    height: auto;
    font-weight: normal;
    text-transform: none;
    line-height: 25px;
    padding: 10px 20px;
    border-color: #636363;
    color: #636363;
  }
  .custom-checkbox {
    padding-left: 34px;
    line-height: 24px;
    margin-bottom: 10px;
    label {
      font-size: 16px;
      &:before {
        top: 0;
        left: -34px;
        border-radius: 0;
        width: 24px;
        height: 24px;
        border-color: #636363;
      }
      &:after {
        top: 0;
        left: -34px;
        height: 24px;
        width: 24px;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #003946;
    }
  }
  @include breakpoint(sm) {
    .form-buttons {
      .btn {
        width: 100%;
        margin-bottom: 15px !important;
        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

// 1060
// 410
// ----
// 650

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.add-state-announcement {
  .g-mb-1 {
    margin-bottom: 42px;
  }
  .g-mb-2 {
    margin-bottom: 60px;
    @include breakpoint(sm) {
      margin-bottom: 45px;
    }
  }
  .i-tooltip {
    vertical-align: super;
    svg path {
      fill: #000 !important;
    }
  }
  .view-1 {
    @include breakpoint(sm) {
      .btn-group {
        width: 100%;
      }
    }
  }
  .view-2 {
    .custom-control-inline {
      display: flex;
      margin-left: 30px;
      margin-bottom: 0;
      align-items: center;
      @include breakpoint(sm) {
        margin-left: 0;
      }
    }
    @include breakpoint(sm) {
      .btn-group {
        flex-wrap: wrap;
        width: 100%;
        .btn {
          width: 50%;
          flex: 0 1 auto;
          padding: 12px 5px;
        }
      }
      .member-type {
        flex-wrap: wrap;
        .btn-group {
          margin-bottom: 30px;
          @include breakpoint(sm) {
            // margin-bottom: ;
          }
          .btn {
            width: 33%;
          }
        }
      }
    }
  }
  .view-3 {
    input::placeholder {
      text-transform: uppercase;
      color: #636363;
      font-weight: bold;
    }
  }
  .view-4 {
    .col {
      display: block;
    }
    .col-a {
      max-width: 60%;
      flex-basis: 60%;
      @include breakpoint(sm) {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 30px;
      }
    }
    .col-b {
      max-width: 40%;
      flex-basis: 40%;
      @include breakpoint(sm) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
    section {
      margin-top: 20px;
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 22px;
      font-size: 16px;
      line-height: 22px;
    }
    .form-attachment {
      margin-bottom: 10px;
    }
    .calendar {
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
        path {
          fill: #636363 !important;
        }
      }
    }
  }
  .send-notifications {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .form-attachment {
    .attachment-list {
      margin-bottom: 20px;
      span {
        margin: 0 30px 0 10px;
        color: #1e68fb;
      }
      svg:last-of-type {
        width: 14px;
        height: 14px;
      }
      @include breakpoint(sm) {
        .btn {
          width: auto !important;
        }
      }
    }
  }
}
.page-navigation {
  display: flex;
  align-items: center;
  padding: 30px 80px 100px;
  @include breakpoint(sm) {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 30px 0 30px;
  }
  .nav-item {
    position: relative;
    @include breakpoint(sm) {
      width: 100%;
    }
    .icon {
      svg {
        fill: #8c8c8c !important;
      }
    }
    &.active {
      .icon {
        background-color: $secondary-color;
        svg {
          fill: #000000 !important;
        }
      }
      .text {
        color: #000;
      }
    }
  }
  .sep {
    height: 9px;
    flex: 1 1 auto;
    background-color: #ccc;
    &.active {
      background-color: $secondary-color;
    }
    @include breakpoint(sm) {
      position: relative;
      height: 30px;
      width: 9px;
      flex: 0 0 auto;
      left: 27px;
      transform: translateX(-50%);
    }
  }
  .icon {
    position: relative;
    height: 54px;
    width: 54px;
    flex: 0 0 auto;
    background-color: #ccc;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text {
    display: block;
    position: absolute;
    width: 140px;
    top: 100%;
    left: 50%;
    margin-top: 13px;
    transform: translateX(-50%);
    color: #8c8c8c;
    font-family: $open-sans;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
    @include breakpoint(sm) {
      width: calc(100% - 70px);
      left: 70px;
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      text-align: left;
    }
  }
}
.checkbox-row {
  display: flex;
  flex-wrap: wrap;
  .checkbox-block {
    min-width: 275px;
    margin-right: 20px;
    padding: 24px 20px 35px 30px;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    @include breakpoint(sm) {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
  .bg-block-h {
    display: flex;
    margin-bottom: 15px;
    h3 {
      flex: 1 1 auto;
      margin: 0;
      color: $gray-200;
      font-family: $open-sans;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: initial;
      line-height: 22px;
      text-transform: none;
    }
    .toggle {
      height: 25px;
      width: 25px;
      flex: 0 0 auto;
      border: 2px solid $gray-200;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before,
      &:after {
        content: '';
        display: block;
        width: 7px;
        height: 2px;
        border-radius: 2px;
        transform: rotate(45deg);
        transform-origin: bottom;
        background-color: $gray-200;
        right: -1px;
        position: relative;
      }
      &:after {
        left: -1px;
        right: auto;
        transform: rotate(-45deg);
      }
    }
  }
  label {
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
